import { GoogleOAuthProvider } from "@react-oauth/google"
import React, { useEffect, useMemo, useState } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import styled from "styled-components"

import { trackEvent } from "lib/eventTracker"

import {
  removeAuthData,
  updateSpearlyOauthTokenNonceAction,
} from "../actions/auth"
import { loadProfile } from "../actions/profile"
import LoggedInProfile from "../components/Login/LoggedInProfile"
import LoginAttention from "../components/Login/LoginAttention"
import LoginHeader from "../components/Login/LoginHeader"
import LoginMain from "../components/Login/LoginMain"
import LoginOauthButton from "../components/Login/LoginOauthButton"
import GoogleOauthButton from "../components/molecules/GoogleOauthButton"
import { Loading } from "../components/molecules/Loading"
import { useLoginButtonVisibility } from "../hooks/useLoginButtonVisibility"
import { useOauth } from "../hooks/useOauth"
import { getSpearlyToken } from "../lib/spearlyToken"
import useProfile from "../recoils/atoms/profile"
import useAuth from "../recoils/atoms/useAuth"

function Login(): JSX.Element {
  const { showFigmaButton, showGoogleButton, showGithubButton } =
    useLoginButtonVisibility()
  const { onFigma, onGitHub } = useOauth("signin")
  const [profile, setProfile] = useRecoilState(useProfile)
  const [token, setToken] = useState(getSpearlyToken())
  const setAuth = useSetRecoilState(useAuth)
  const searchParams = new URLSearchParams(window.location.search)
  const authedRedirectUri = searchParams.get("redirect_uri") || "/profile"
  const nonce = searchParams.get("nonce")
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""
  const [hasFigmaPluginLoginCompleted, setHasFigmaPluginLoginCompleted] =
    useState(false)

  const redirectTo = async () => {
    try {
      await loadProfile(setAuth, setProfile)
      window.location.href = authedRedirectUri
    } catch (error) {
      console.error(error)
      removeAuthData()
    }
  }

  const updateSpearlyOauthTokenNonce = async (nonce: string) => {
    try {
      await updateSpearlyOauthTokenNonceAction(nonce)
      setHasFigmaPluginLoginCompleted(true)
    } catch (error: unknown) {
      console.error(error)
      // regard this as a failure of login. Switch to login page.
      setToken("")
    }
  }

  useEffect(() => {
    trackEvent({ eventName: "Visit Login Page" })
  }, [])

  useEffect(() => {
    if (!token) return

    if (nonce) {
      updateSpearlyOauthTokenNonce(nonce)
    } else {
      redirectTo()
    }
  }, [token])

  const isVisibleProfile = useMemo(() => {
    return token && profile && profile.name !== ""
  }, [token, profile])

  const isFigmaPluginMode = useMemo(() => {
    return !!nonce
  }, [nonce])

  // Show two types of loading screen
  // 1. when the user visits the page from the Figma plugin.
  // 2. when the user logs in in a normal way.
  return nonce && token ? (
    <Loading
      isShowFigmaPluginLogo={isFigmaPluginMode}
      hasFigmaLoginCompleted={hasFigmaPluginLoginCompleted}
    />
  ) : (
    <GoogleOAuthProvider clientId={clientId}>
      <Root>
        <LoginHeader />
        <LoginMenu>
          <LoginMain isFigmaPlugin={isFigmaPluginMode} />
          <ButtonList>
            {isVisibleProfile && <LoggedInProfile profile={profile} />}
            <LoginOauthButton
              isVisible={
                isFigmaPluginMode && showFigmaButton && !isVisibleProfile
              }
              oauthType="figma"
              onClick={() => onFigma()}
            />
            <GoogleOauthButton
              isVisible={showGoogleButton && !isVisibleProfile}
              oauthAction="signin"
            />
            <LoginOauthButton
              isVisible={showGithubButton && !isVisibleProfile}
              oauthType="github"
              onClick={() => onGitHub()}
            />
            <LoginOauthButton
              isVisible={
                !isFigmaPluginMode && showFigmaButton && !isVisibleProfile
              }
              oauthType="figma"
              onClick={() => onFigma()}
            />
          </ButtonList>
          <LoginAttention />
        </LoginMenu>
      </Root>
    </GoogleOAuthProvider>
  )
}

const Root = styled.div`
  position: relative;
  background: #fff;
  height: 100vh;
  padding: 40px;

  @media screen and (max-width: 1000px) {
    padding: 20px;
  }
`

const LoginMenu = styled.div`
  position: absolute;
  top: 30%;
  right: 0;
  left: 0;
`

const ButtonList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px 0;
  margin: 30px auto 53px;
`

export default Login
